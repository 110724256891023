import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import { Turnstile } from '@marsidev/react-turnstile'
import useUser from 'lib/Hooks';
import {
  CheckCircleIcon,
  ExclamationIcon,
  PlusCircleIcon,
  ShoppingBagIcon,
} from '@heroicons/react/outline';
import instance from 'lib/AxiosClient';
import { Link, useHref } from 'react-router-dom';
import { PayPalButton } from 'react-paypal-button-v2';
import FadeIn from 'react-fade-in';
import CoinbaseCommerceButton from 'react-coinbase-commerce';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';
import { useTheme } from 'lib/ThemeContext';
import loadable from '@loadable/component';
import { classNames } from 'lib/CustomFunctions';
import { CreditCardIcon, GiftIcon } from '@heroicons/react/solid';
import Spinner from 'components/Spinner';
const BuyButton = loadable(() => import('components/BuyNow'));
// import logo from '../../assets/logo.png';

const tabs = [
  { id: 0, name: 'Card/Crypto ', sellerLock: false },
  { id: 1, name: 'PayPal/Card', sellerLock: false },
];

const handleOtherOptionClick = (planType: any, paymentMethod: any) => {
  toast.promise(
    instance.post('/v1/purchase-options', {
      paymentMethod,
      planType,
    }),
    {
      loading: 'Loading...',
      success: (response) => {
        //@ts-ignore
        window.open(response.data, '_blank');
        return 'Checkout successfully created! If you have not been redirected, then please try a different browser.';
      },
      error: (err) => {
        return 'Card sales are currently out of stock, please try again later.';
      },
      
    },
    {
      style: {
        background: '#1e1e1e',
        color: '#ffffff',
      },
      success: {
        duration: 5000,
      },
    }
    
  )
};




const giftCardLists = [
  {
    id: 1,
    title: '$10 Gift Card',
    link: 'https://www.kinguin.net/category/145034/serverside-fun-10-gift-card',
    description: 'Gives you a STANDARD whitelist instantly. ',
  },
  {
    id: 2,
    title: '$50 Gift Card',
    link: 'https://www.kinguin.net/category/146001/serverside-fun-10-gift-card',
    description: 'Gives you a PREMIUM whitelist instantly.',
  },
  {
    id: 3,
    title: '$100 Gift Card',
    link: 'https://www.kinguin.net/category/146003/serverside-fun-100-gift-card',
    description: 'Gives you a PLUS whitelist instantly.',
  },
];

const paymentMethods = [
  {
    id: 1,
    title: 'Credit/Debit Card',
    icon: <CreditCardIcon className="mr-2 w-4" />,
    link: 'https://www.kinguin.net/listing?active=1&hideUnavailable=0&phrase=rbxexchange&page=0&size=50&sort=bestseller.total,DESC',
    description:
      'Instantly purchase with your credit/debit card. You will receive a info in your email.',
    color: 'blue-600',
  },
  {
    id: 2,
    icon: <i className="fab fa-bitcoin mr-2" />,
    title: 'Crypto',
    description: 'Blockchain transactions are handled by Coinbase Commerce.',
    color: 'red-500',
  },
];

const planTypes = [
  {
    id: 3,
    title: 'Standard',
    description: 'Perfect for basic users trying the service out.',
  },
  {
    id: 4,
    title: 'Premium',
    description: 'Allows you to bypass our blacklist policy.',
  },
];

function PurchaseModal(props: { open: any; setOpen: any; callback: any }) {
  const ref = useRef(null)
  const [token1, setToken] = useState()
  const [currentToken, setCurrentToken] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const { user } = useUser();
  const { dark } = useTheme();

  const cancelButtonRef = useRef(null);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paymentMethods[0],
  );

  const [selectedPlanType, setSelectedPlanType] = useState(planTypes[0]);
 const [buttonKey, setButtonKey] = useState(0);
 useEffect(() => {
   setButtonKey(Math.random());
 }, []);
  // tab state

  const [openTab, setOpenTab] = useState(0);

  // Input States

  // gift card states
  const [selectedgiftCardLists, setSelectedgiftCardLists] = useState(
    giftCardLists[0],
  );
  const [giftCardCode, setGiftCardCode] = useState('');
  const [submittingGiftCode, setSubmittingGiftCode] = useState(false);
  const [selectedTabName, setSelectedTabName] = useState(tabs[0].name);
  const [depositAmount, setDepositAmount] = useState<number>(0);

  const [depositing, setDepositing] = useState(false);

  const handleSubmit = (giftCardCode: string, token1: undefined) => {
    if(!token1)
    {
      toast.error('Please complete the captcha', {
        style: {
          background: '#1e1e1e',
          color: '#ffffff',
        },
        });
      return;
    }

    toast.promise(
      instance.post('/v1/redeem', {
        giftCardCode,
  token1
      }),
      {
        loading: 'Checking code...',
        success: (response) => {
          window.location.href = 'https://serverside.fun/?conversion=true';
          return response.data.message;
        },
        error: (err) => {
          //@ts-ignore
          ref.current?.reset()
          return err.response.data.message;
        },
      },
       {
        style: {
          background: '#1e1e1e',
          color: '#ffffff',
        },
        success: {
          duration: 2500,
        },
      }
    )
  };
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 z-10 ${dark ? 'dark' : ''} overflow-y-auto`}
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0 backdrop-"
          >
            <Dialog.Overlay className="fixed inset-0 z-0 bg-gray-900 bg-opacity-75 transition-opacity dark:bg-darkMode-200 dark:bg-opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform overflow-hidden rounded-lg border-2 bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all dark:border-darkMode-500 dark:bg-darkMode-400 sm:my-8 sm:w-full sm:max-w-xl sm:p-6 sm:align-middle">
              <div className="flex space-x-2">
                <div className="flex h-9 w-9 items-center justify-center rounded-full bg-blue-100 dark:bg-blue-800 dark:bg-opacity-30">
                  <CreditCardIcon
                    className="h-5 w-5 text-blue-600"
                    aria-hidden="true"
                  />
                </div>
                <p className="mt-0.5 text-lg font-semibold dark:text-white">
                  Purchase
                </p>
              </div>

              <div className="mb-2">
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  <select
                    id="tabs"
                    name="tabs"
                    className="mt-4 block w-full rounded-md border-gray-300 focus:border-red-500 focus:ring-red-500 dark:border-darkMode-300 dark:bg-darkMode-200 dark:text-white"
                    defaultValue={tabs[0].name}
                    value={selectedTabName}
                    onChange={(event) => {
                      setSelectedTabName(event.target.value);
                      const selectedTab = tabs.find(
                        (tab) => tab.name === event.target.value,
                      );
                      if (selectedTab) {
                        setOpenTab(selectedTab.id);
                      }
                    }}
                  >
                    {tabs.map((tab) =>
                      tab.sellerLock && !user?.seller ? null : (
                        <option key={tab.name}>{tab.name}</option>
                      ),
                    )}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <div className="border-b border-gray-200 dark:border-darkMode-300">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      {tabs.map((tab) =>
                        tab.sellerLock && !user?.seller ? null : (
                          <button
                            key={tab.name}
                            onClick={() => setOpenTab(tab.id)}
                            className={classNames(
                              tab.id == openTab
                                ? 'border-red-500 text-red-600'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 hover:dark:border-darkMode-300 hover:dark:text-white',
                              'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-semibold transition',
                            )}
                            aria-current={
                              tab.id == openTab ? 'page' : undefined
                            }
                          >
                            {tab.name}
                          </button>
                        ),
                      )}
                    </nav>
                  </div>
                </div>
              </div>

              {openTab == 0 && (
                <>
                  <div>
                    <RadioGroup
                      value={selectedPaymentMethod}
                      onChange={setSelectedPaymentMethod}
                    >
                      <RadioGroup.Label className="text-base font-semibold dark:text-white">
                        Select Payment Method
                      </RadioGroup.Label>

                      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                        {paymentMethods.map((paymentMethod) => (
                          <RadioGroup.Option
                            key={paymentMethod.id}
                            value={paymentMethod}
                            className={({ checked, active }) =>
                              classNames(
                                active
                                  ? `border-${selectedPaymentMethod?.color}`
                                  : 'border-transparent',
                                'relative flex cursor-pointer rounded-lg border-2 bg-transparent p-4 shadow-sm transition focus:outline-none dark:border-darkMode-500 hover:scale-[102%] cursor-pointer hover:shadow-lg',
                              )
                            }
                          >
                            {({ checked, active }) => (
                              <>
                                <span className="flex flex-1">
                                  <span className="flex flex-col">
                                    <RadioGroup.Label
                                      as="span"
                                      className="block text-sm font-semibold dark:text-white"
                                    >
                                      {paymentMethod.title}
                                    </RadioGroup.Label>
                                    <RadioGroup.Description
                                      as="span"
                                      className="mt-1 flex items-center text-sm text-gray-500"
                                    >
                                      {paymentMethod.description}
                                    </RadioGroup.Description>
                                  </span>
                                </span>
                                <CheckCircleIcon
                                  className={classNames(
                                    !checked ? 'scale-0' : 'scale-100',
                                    `text-${selectedPaymentMethod?.color} h-5 w-5 transition`,
                                  )}
                                  aria-hidden="true"
                                />
                                <span
                                  className={classNames(
                                    active ? 'border' : 'border-2',
                                    checked
                                      ? `border-${selectedPaymentMethod?.color}`
                                      : 'border-transparent ',
                                    'pointer-events-none absolute -inset-px rounded-lg ',
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>

                  <div className="mt-4">
                    <RadioGroup
                      value={selectedPlanType}
                      onChange={setSelectedPlanType}
                    >
                      <RadioGroup.Label className="text-base font-semibold dark:text-white">
                        Select Plan Type
                      </RadioGroup.Label>

                      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                        {planTypes.map((paymentMethod) => (
                          <RadioGroup.Option
                            key={paymentMethod.id}
                            value={paymentMethod}
                            className={({ checked, active }) =>
                              classNames(
                                checked
                                  ? 'border-transparent'
                                  : 'border-gray-300',
                                active
                                  ? `border-${selectedPaymentMethod?.color} `
                                  : '',
                                `relative flex cursor-pointer rounded-lg border-2 bg-transparent p-4 shadow-sm transition focus:outline-none dark:border-darkMode-500 hover:scale-[102%] cursor-pointer hover:shadow-lg `,
                              )
                            }
                          >
                            {({ checked, active }) => (
                              <>
                                <span className="flex flex-1">
                                  <span className="flex flex-col">
                                    <RadioGroup.Label
                                      as="span"
                                      className="block text-sm font-semibold dark:text-white"
                                    >
                                      {paymentMethod.title}
                                    </RadioGroup.Label>
                                    <RadioGroup.Description
                                      as="span"
                                      className="mt-1 flex items-center text-sm text-gray-500"
                                    >
                                      {paymentMethod.description}
                                    </RadioGroup.Description>
                                  </span>
                                </span>
                                <CheckCircleIcon
                                  className={classNames(
                                    !checked ? 'scale-0' : 'scale-100',
                                    `text-${selectedPaymentMethod?.color} h-5 w-5 transition`,
                                  )}
                                  aria-hidden="true"
                                />
                                <span
                                  className={classNames(
                                    active ? 'border' : 'border-2',
                                    checked
                                      ? `border-${selectedPaymentMethod?.color}`
                                      : 'border-transparent',
                                    'pointer-events-none absolute -inset-px rounded-lg',
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                  {selectedPaymentMethod?.id === 2 ? (
                    <div key={`${buttonKey}`}>
                      <button
                        data-sellix-group="64b6f11c19a1a"
                        type="submit"
                        className={`mt-3 inline-flex w-full items-center justify-center rounded-md border-[3px] hover:cursor-pointer border-${selectedPaymentMethod?.color} bg-transparent px-5 py-3 text-base font-semibold text-${selectedPaymentMethod?.color} shadow-sm transition focus:outline-none disabled:bg-${selectedPaymentMethod?.color} sm:text-sm`}
                      >
                        Purchase via Crypto
                      </button>
                    </div>
                  ) : selectedPaymentMethod?.link ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className={`mt-3 inline-flex w-full items-center justify-center rounded-md border-[3px] hover:cursor-pointer border-${selectedPaymentMethod?.color} bg-transparent px-5 py-3 text-base font-semibold text-${selectedPaymentMethod?.color} shadow-sm transition focus:outline-none disabled:bg-${selectedPaymentMethod?.color} sm:text-sm`}
                      onClick={() =>
                        handleOtherOptionClick(
                          selectedPlanType,
                          selectedPaymentMethod,
                        )
                      }
                    >
                      {depositing ? (
                        <>
                          <Spinner className="mr-3 h-5 w-5 text-white" />{' '}
                          Generating Checkout...
                        </>
                      ) : (
                        <>
                          {selectedPaymentMethod.icon}
                          Purchase {selectedPlanType.title}
                        </>
                      )}
                    </a>
                  ) : (
                    <a
                      href={selectedPaymentMethod.link}
                      target="_blank"
                      rel="noreferrer"
                      className={`mt-3 inline-flex w-full items-center justify-center rounded-md border-[3px] hover:cursor-pointer border-${selectedPaymentMethod?.color} bg-transparent px-5 py-3 text-base font-semibold text-${selectedPaymentMethod?.color} shadow-sm transition focus:outline-none disabled:bg-${selectedPaymentMethod?.color} sm:text-sm`}
                      onClick={() =>
                        handleOtherOptionClick(
                          selectedPlanType,
                          selectedPaymentMethod,
                        )
                      }
                    >
                      {depositing ? (
                        <>
                          <Spinner className="mr-3 h-5 w-5 text-white" />{' '}
                          Generating Checkout...
                        </>
                      ) : (
                        <div>
                          Purchase{' '}
                          {selectedPlanType.title +
                            ' via ' +
                            selectedPaymentMethod.title}
                        </div>
                      )}
                    </a>
                  )}
                </>
              )}
              {openTab == 1 && (
                <>
                  <RadioGroup
                    value={selectedgiftCardLists}
                    onChange={setSelectedgiftCardLists}
                  >
                    <p className="text-base font-bold text-gray-900 dark:text-white">
                      Purchase Gift Cards
                    </p>

                    <div className="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-3">
                      {giftCardLists.map((giftCardList) => (
                        <a
                          key={giftCardList.id}
                          href={giftCardList.link}
                          target="_blank"
                          className="relative flex cursor-pointer rounded-lg border-2 bg-white p-4 shadow-sm transition hover:border-blue-600 focus:outline-none dark:border-darkMode-100 dark:bg-darkMode-200 hover:scale-[102%] cursor-pointer hover:shadow-lg hover:ring-1 hover:ring-red-500"
                          rel="noreferrer"
                        >
                          <span className="flex flex-1">
                            <span className="flex flex-col">
                              <RadioGroup.Label
                                as="span"
                                className="block text-sm font-bold text-gray-900 dark:text-white"
                              >
                                {giftCardList.title}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className="mt-1 flex items-center text-xs text-gray-500"
                              >
                                {giftCardList.description}
                              </RadioGroup.Description>
                            </span>
                          </span>

                          <span
                            className="pointer-events-none absolute -inset-px rounded-lg"
                            aria-hidden="true"
                          />
                        </a>
                      ))}
                    </div>
                  </RadioGroup>

                  <div className="relative mt-4 mb-2">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-300 dark:border-darkMode-100" />
                    </div>
                    <div className="relative flex justify-center">
                      <span className="bg-white px-2 text-sm text-gray-500 dark:bg-darkMode-400 ">
                        Or redeem a gift card
                      </span>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-bold dark:text-white">
                      Gift Card Code
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        className="block w-full rounded-md border-2 border-gray-300 shadow-sm transition focus:border-blue-600 dark:border-darkMode-100 dark:bg-darkMode-200 dark:text-white sm:text-sm"
                        placeholder="XXXX-XXXX-XXXX"
                        onChange={(e) => setGiftCardCode(e.target.value)}
                        aria-describedby="email-description"
                      />
                    </div>
                    <p
                      className="mt-2 text-sm text-gray-500"
                      id="email-description"
                    >
                      All codes are valid for one-time use, redeemable globally.
                    </p>
                  </div>
                  <div className="mt-2 mx-auto flex justify-center text-center">
                    <Turnstile
                      ref={ref}
                      siteKey="0x4AAAAAAACBXGxWvcLswhjZ"
                      //@ts-ignore
                      onSuccess={(token1) => setToken(token1)}
                      //@ts-ignore
                      onExpire={() => ref.current?.reset()}
                    />
                  </div>
                  <button
                    type="button"
                    disabled={submittingGiftCode}
                    onClick={() => handleSubmit(giftCardCode, token1)}
                    className="mt-3 inline-flex w-full justify-center rounded-md border-2 border-transparent bg-red-600 px-5 py-3 text-base font-bold text-white shadow-sm transition hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:dark:ring-offset-darkMode-200 sm:text-sm"
                  >
                    {submittingGiftCode ? (
                      <>
                        <Spinner className="mr-1 w-4" /> Redeeming Code...
                      </>
                    ) : (
                      <>
                        <GiftIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        Redeem Gift Card
                      </>
                    )}
                  </button>
                </>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default PurchaseModal;
