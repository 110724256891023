import { SearchIcon } from '@heroicons/react/solid';
import { classNames } from 'lib/CustomFunctions';
import SideNavContainer from 'components/SideNavContainer';
import ReactCountryFlag from 'react-country-flag';
import { useEffect, useState } from 'react';
import useUser, { usePlayers } from 'lib/Hooks';
import FadeIn from 'react-fade-in';
import {
  CheckCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline';
import toast from 'react-hot-toast';
import instance from 'lib/AxiosClient';

function Players() {
  const { players, isLoading } = usePlayers();
  const [selectedPlayer, setSelectedPlayer] = useState<any>(null);
  const [walkSpeed, setWalkSpeed] = useState<number>(16);
  const [jumpPower, setJumpPower] = useState<number>(50);

  const [search, setSearch] = useState<string>('');

  const { user } = useUser();

  const essentialActions = [
    {
      name: 'Punish',
      script: `for _, part in pairs(game.Players:GetPlayerByUserId(${selectedPlayer?.userid}).Character:GetDescendants()) do if part:IsA("BasePart") then part.Transparency = 1; part.Anchored = true; elseif part:IsA("Accessory") then part:Destroy(); end end`,
    },
    {
      name: 'Kick',
      script: `game.Players:GetPlayerByUserId(${selectedPlayer?.userid}):Kick();`,
    },
    {
      name: 'Bring Player',
      script: `game.Players:GetPlayerByUserId(${selectedPlayer?.userid}).Character.HumanoidRootPart.CFrame = game.Players:GetPlayerByUserId(${user?.buyer?.robloxId || user?.trial?.robloxId}).Character.HumanoidRootPart.CFrame;`,
    },
    {
      name: 'Teleport To',
      script: `game.Players:GetPlayerByUserId(${user?.buyer?.robloxId || user?.trial?.robloxId}).Character.HumanoidRootPart.CFrame = game.Players:GetPlayerByUserId(${selectedPlayer?.userid}).Character.HumanoidRootPart.CFrame;`,
    },
    {
      name: 'R6 Player',
      script: `pcall(function()require(3436957371):r6("${selectedPlayer?.username}")end)`,
    },
    {
      name: 'Respawn Player',
      script: `game.Players:GetPlayerByUserId(${selectedPlayer?.userid}):LoadCharacter();`,
    },
  ];

  const trollActions = [
    {
      name: 'Kill',
      script: `game.Players:GetPlayerByUserId(${selectedPlayer?.userid}).Character:BreakJoints();`,
    },
    {
      name: 'Stun',
      script: `game.Players:GetPlayerByUserId(${selectedPlayer?.userid}).Character.Humanoid.PlatformStand = true;`,
    },
    {
      name: 'Freeze',
      script: `for _, part in pairs(game.Players:GetPlayerByUserId(${selectedPlayer?.userid}).Character:GetChildren()) do if part:IsA("BasePart") then part.Anchored = true; end end`,
    },
    {
      name: 'Thaw',
      script: `for _, part in pairs(game.Players:GetPlayerByUserId(${selectedPlayer?.userid}).Character:GetChildren()) do if part:IsA("BasePart") then part.Anchored = false; end end`,
    },
    {
      name: 'God Mode',
      script: `game.Players:GetPlayerByUserId(${selectedPlayer?.userid}).Character.Humanoid.MaxHealth = math.huge;`,
    },
    {
      name: 'Semi-God Mode',
      script: `game.Players:GetPlayerByUserId(${selectedPlayer?.userid}).Character.Humanoid.MaxHealth = 1000;`,
    },
    {
      name: 'Free Fall',
      script: `local p=game.Players:GetPlayerByUserId(${selectedPlayer?.userid});p.Character.HumanoidRootPart.CFrame = CFrame.new(p.Character.HumanoidRootPart.Position + Vector3.new(0, 500, 0));`,
    },
    {
      name: 'Explode',
      script: `local p=game.Players:GetPlayerByUserId(${selectedPlayer?.userid}).Character.HumanoidRootPart;local e=Instance.new("Explosion",p);e.Position=p.Position;e.BlastRadius=10;`,
    },

    {
      name: 'Naked',
      script: `for _, item in pairs(game.Players:GetPlayerByUserId(${selectedPlayer?.userid}).Character:GetDescendants()) do if item:IsA("Accessory") or item:IsA("Clothing") then item:Destroy(); end end`,
    },
    {
      name: 'Jail',
      script: `local p = game.Players:GetPlayerByUserId(${selectedPlayer?.userid}); local c = p.Character.HumanoidRootPart.Position; local walls = {{2.5,0,0,1,5,5},{-2.5,0,0,1,5,5},{0,0,2.5,5,5,1},{0,0,-2.5,5,5,1},{0,2.5,0,5,1,5},{0,-2.5,0,5,1,5}}; for _, v in pairs(walls) do local b = Instance.new("Part", p.Character); b.Anchored = true; b.Size = Vector3.new(v[4],v[5],v[6]); b.Transparency = 0.5; b.CFrame = CFrame.new(c.x+v[1],c.y+v[2],c.z+v[3]); end; `,
    },
    {
      name: 'Sit',
      script: `game.Players:GetPlayerByUserId(${selectedPlayer?.userid}).Character.Humanoid.Sit = true;`,
    },
    {
      name: 'Forcefield',
      script: `Instance.new("ForceField", game.Players:GetPlayerByUserId(${selectedPlayer?.userid}).Character)`,
    },
    {
      name: 'No Forcefield',
      script: `local player = game.Players:GetPlayerByUserId(${selectedPlayer?.userid}); for _, v in pairs(player.Character:GetChildren()) do if v:IsA('ForceField') then v:Destroy() end end`,
    },

    {
      name: 'Rinn',
      script: `local target = game.Players:GetPlayerByUserId(${selectedPlayer?.userid}); for _, part in pairs(target.Character:GetDescendants()) do if part:IsA("BasePart") then part.Size = Vector3.new(2, 10, 2) end end`,
    },

    {
      name: 'Small',
      script: `local target = game.Players:GetPlayerByUserId(${selectedPlayer?.userid}); for _, part in pairs(target.Character:GetDescendants()) do if part:IsA("BasePart") then part.Size = Vector3.new(3, 3, 3) end end`,
    },
    {
      name: 'Sparkles',
      script: `for a,b in ipairs(game.Players:GetPlayers())do if b.UserId==${selectedPlayer?.userid} and b.Character then local c=Instance.new('Sparkles')c.Parent=b.Character:WaitForChild('HumanoidRootPart')end end`,
    },
    {
      name: 'Smoke',
      script: `local a=${selectedPlayer?.userid};for b,c in ipairs(game.Players:GetPlayers())do if c.UserId==a and c.Character then local d=Instance.new('Smoke')d.Opacity=0.5;d.Color=Color3.new(0.1,0.1,0.1)d.Size=2;d.RiseVelocity=1;d.Parent=c.Character:WaitForChild('HumanoidRootPart')end end`,
    },
    {
      name: 'Spawn Car',
      script: `require(13860266631).spawn(${selectedPlayer?.userid})`,
    },
    {
      name: 'Hamster Ball',
      script: `require(13860357843).spawn(${selectedPlayer?.userid})`,
    },
  ];

  const submitCode = (script: any) => {
    if (!user.buyer && !user.trial)
      return toast.error('Unauthorized.', {
        style: {
          background: '#1e1e1e',
          color: '#ffffff',
        },
      });

    toast.promise(
      instance.post('/v1/long-polling', {
        script,
        robloxId: (user?.buyer?.robloxId || user?.trial?.robloxId),
        cmd: true,
      }),
      {
        loading: 'Executing script...',
        success: (response) => {
          return 'Successfully sent command to player.';
        },
        error: (err) => {
          return err.response.data.message;
        },
      },
      {
        style: {
          background: '#1e1e1e',
          color: '#ffffff',
        },
        success: {
          duration: 2500,
        },
      },
    );
  };

  useEffect(() => {
    // if theres a selected player but the player list is empty, reset the selected player
    if (selectedPlayer && players?.length === 0) {
      setSelectedPlayer(null);
    }

    // if the player is not in the player list, reset the selected player

    if (
      selectedPlayer &&
      !players?.some((player: any) => player?.userid === selectedPlayer?.userid)
    ) {
      setSelectedPlayer(null);
    }
  }, [players]);

  const filteredPlayers = players?.filter(
    (player: any) =>
      player?.username.toLowerCase().includes(search.toLowerCase()) ||
      player?.userid === search ||
      player?.displayName.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <SideNavContainer title="Players" header={false}>
      <FadeIn delay={50}>
        <div className="grid lg:grid-cols-7 grid-cols-1 lg:divide-x-2 divide-darkMode-400 divide-y-2 lg:divide-y-0 gap-6 border-2 border-darkMode-400 rounded-lg p-4 text-white">
          <div id="playerList" className="lg:col-span-2">
            <div className="relative mb-3 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                className="block w-full rounded-md border-0 py-1.5 pl-10 bg-darkMode-400 text-white ring-1 ring-inset ring-darkMode-500 placeholder:text-gray-400 focus:ring-1 transition focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                placeholder="Search Players"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </div>

            <div className="space-y-4 overflow-auto max-h-screen">
              {players?.length > 0
                ? filteredPlayers?.map((player: any, i: number) => (
                    <button
                      type="button"
                      key={i}
                      onClick={() => {
                        // toggle selected player
                        if (selectedPlayer === player) {
                          setSelectedPlayer(null);
                        } else {
                          setSelectedPlayer(player);
                        }
                      }}
                      className={classNames(
                        selectedPlayer === player
                          ? 'border-red-500 bg-red-700/10 shadow-md shadow-red-500/20'
                          : 'bg-darkMode-400 hover:border-red-500 hover:bg-red-700/10 hover:shadow-md hover:shadow-red-500/20 border-darkMode-500',
                        'space-x-4 transition text-left flex w-full items-center rounded-md p-2 border',
                      )}
                    >
                      <img
                        className="rounded-full bg-darkMode-200 w-14 h-14"
                        src={`https://api.serverside.fun/v1/proxy?userId=${player?.userid}`}
                        alt="avatar"
                      />

                      <div className="font-medium overflow-hidden">
                        <p className="text-gray-300 truncate">
                          {player?.displayName}
                        </p>
                        <p className="text-gray-500 truncate">
                          @{player?.username}
                        </p>
                      </div>
                    </button>
                  ))
                : Array.from({ length: 6 }).map((_, i) => (
                    <div
                      id="playerLoading"
                      className="bg-darkMode-400 flex border rounded-md items-center p-2 border-darkMode-500"
                    >
                      <div className="rounded-full h-14 w-14 skeletonDark bg-darkMode-200" />
                      <div className="ml-4">
                        <div className="h-4 skeletonDark bg-darkMode-200 rounded w-52 mb-1" />
                        <div className="h-4 skeletonDark bg-darkMode-200 rounded w-28" />
                      </div>
                    </div>
                  ))}
            </div>
          </div>
          <div className="lg:col-span-5 pt-6 lg:pt-0 lg:pl-6">
            {selectedPlayer ? (
              <div id="playerInfo">
                <div className="flex space-x-5">
                  <img
                    className="rounded-full bg-darkMode-400 h-24 w-24 lg:w-48 lg:h-48"
                    src={`https://api.serverside.fun/v1/proxy?userId=${selectedPlayer?.userid}`}
                    alt="avatar"
                  />

                  <div className="font-medium text-base lg:text-2xl space-y-1 overflow-hidden w-full">
                    <p className="text-gray-300 truncate">
                      {selectedPlayer?.displayName}
                    </p>
                    <p className="text-gray-500 truncate">
                      @{selectedPlayer?.username}
                    </p>

                    <p>
                      <ReactCountryFlag
                        countryCode={selectedPlayer?.country}
                        className="mr-3"
                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                        svg
                      />
                      <span className="text-gray-500">
                        {new Intl.DisplayNames(['en'], { type: 'region' }).of(
                          selectedPlayer?.country,
                        )}
                      </span>
                    </p>

                    <div id="walkspeedSlider" className="!mt-4">
                      <label className="block text-sm font-medium text-gray-900 dark:text-white">
                        WalkSpeed ({walkSpeed})
                      </label>
                      <input
                        type="range"
                        onChange={(e) => {
                          setWalkSpeed(Number(e.target.value));
                        }}
                        onMouseUp={() => {
                          submitCode(
                            `game.Players:GetPlayerByUserId(${selectedPlayer?.userid}).Character.Humanoid.WalkSpeed = ${walkSpeed};`,
                          );
                        }}
                        value={walkSpeed}
                        defaultValue={16}
                        max={200}
                        className="w-full h-2 bg-darkMode-400 rounded-lg accent-red-500 appearance-none cursor-pointer"
                      />
                    </div>

                    <div id="walkspeedSlider" className="!mt-4">
                      <label className="block text-sm font-medium text-gray-900 dark:text-white">
                        JumpPower ({jumpPower})
                      </label>
                      <input
                        type="range"
                        onChange={(e) => {
                          setJumpPower(Number(e.target.value));
                        }}
                        onMouseUp={() => {
                          submitCode(
                            `game.Players:GetPlayerByUserId(${selectedPlayer?.userid}).Character.Humanoid.JumpPower = ${jumpPower};`,
                          );
                        }}
                        value={jumpPower}
                        max={1000}
                        className="w-full h-2 bg-darkMode-400 accent-red-500 rounded-lg appearance-none cursor-pointer"
                      />
                    </div>
                  </div>
                </div>

                <div id="essentialActions" className="mt-5">
                  <h3 className="text-lg font-bold">Essential Actions</h3>

                  <div className="grid grid-cols-2 gap-2 mt-3">
                    {essentialActions?.map((action, i) => (
                      <button
                        key={i}
                        onClick={() => submitCode(action.script)}
                        className="bg-darkMode-400 scale-100 active:scale-[98%] py-4 focus:outline-none hover:border-red-500 transition hover:bg-red-700/10 hover:shadow-md hover:shadow-red-500/20 rounded-md border font-medium border-darkMode-500"
                      >
                        {action.name}
                      </button>
                    ))}
                  </div>
                </div>

                <div id="trollActions" className="text-white mt-5">
                  <h3 className="text-lg font-bold">Troll Actions</h3>

                  <div className="grid grid-cols-3 gap-2 mt-3">
                    {trollActions?.map((action, i) => (
                      <button
                        className="bg-darkMode-400 scale-100 active:scale-[98%] py-4 focus:outline-none hover:border-red-500 transition hover:bg-red-700/10 hover:shadow-md hover:shadow-red-500/20 rounded-md border font-medium border-darkMode-500"
                        onClick={() => submitCode(action.script)}
                      >
                        {action.name}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            ) : selectedPlayer == null && players?.length > 0 && !isLoading ? (
              <div className="text-white text-center space-y-2 py-48 m-2 flex flex-col items-center justify-center">
                <CheckCircleIcon className="h-16 w-16 mr-2 text-green-500" />
                <p className="text-xl font-medium">Players Loaded</p>
                <p className="text-gray-400">
                  Please select a player from the list to execute specific
                  commands.
                </p>
              </div>
            ) : (
              <div className="text-white text-center space-y-2 py-24 lg:py-48 m-2 flex flex-col items-center justify-center">
                <InformationCircleIcon className="h-16 w-16 text-blue-500" />

                <p className="text-xl font-medium animate-pulse">
                  Waiting For Game...
                </p>
                <p className="text-gray-400 max-w-sm">
                  In order for the player list to load, your linked Roblox
                  account must be in a server that is connected to
                  serverside.fun.
                </p>
              </div>
            )}
          </div>
        </div>
      </FadeIn>
    </SideNavContainer>
  );
}

export default Players;
