import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import useUser from 'lib/Hooks';
import { PlusCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';
import instance from 'lib/AxiosClient';
import { useTheme } from 'lib/ThemeContext';
import { Switch } from '@headlessui/react'
import Tippy from '@tippyjs/react';
import { InformationCircleIcon } from '@heroicons/react/solid';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}


function UpdateAccount(props: { open: any; setOpen: any; callback: any }) {
  const cancelButtonRef = useRef(null);
  const [username, setUsername] = useState('');
  const { user, isFetching } = useUser();
  const { dark } = useTheme();
  const updateAccount = () => {
    toast.promise(
      instance.post('/v1/update-whitelist', {
        robloxUsername: username,
      }),
      {
        loading: `Updating account to ${username}...`,
        success: (response) => {
          props.setOpen(false);
          props.callback();
          return response.data.message;
        },
        error: (err) => {
          props.setOpen(false);
          props.callback();
          return err.response.data.message;
        },
      },
      {
        style: {
          background: '#1e1e1e',
          color: '#ffffff',
        },
        success: {
          duration: 2500,
        },
      }
    );
  };

  const [enabled, setEnabled] = useState(false);
  
  const handleSwitchChange = () => {
    setEnabled(!enabled);
    toast.promise(
      instance.post('/v1/update-whitelist/toggle', {
        enabled: !enabled
      },
      
      ),
      {
        loading: 'Updating settings...',
        //@ts-ignore
       
        success: (response) => `Your watermark has been successfully ${enabled ? 'disabled' : 'enabled'}!`,
        error: (err) => {
          setEnabled(true);
          return `Error updating watermark: ${err.response.data.message}`;
        },

      },
      {
        style: {
          background: '#1e1e1e',
          color: '#ffffff',
        },
        success: {
          duration: 2500,
        },
      }
    );
  };
  
  useEffect(() => {
    if (!isFetching) {
      if(user) {
        if(user.buyer) {
          setEnabled(user.buyer.watermark);
        }
        if(!user.buyer) {
          setEnabled(true);
        }
      }
    }
  }, [isFetching]); // this will cause the effect to run whenever isFetching changes
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 z-10 ${dark ? 'dark' : ''} overflow-y-auto`}
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 dark:bg-darkMode-200 dark:bg-opacity-75 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
            
              <Dialog.Panel className="relative bg-white dark:bg-darkMode-200 border-2 border-gray-200 dark:border-darkMode-100 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 dark:bg-green-800 dark:bg-opacity-30 sm:mx-0 sm:h-10 sm:w-10">
                    <PlusCircleIcon
                      className="h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />                  
                  </div>
                  <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-black dark:text-white"
                    >
                      Manage Whitelist
                    </Dialog.Title>
                    <div className="mt-2">          
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-white"
                        >
                          ROBLOX Username
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            onChange={(e) => setUsername(e.target.value)}
                            className="shadow-sm focus:ring-blue-600 transition dark:text-white dark:bg-darkMode-200 dark:border-darkMode-100 focus:border-blue-600 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder={
                              (user &&
                                user.buyer &&
                                user.buyer.robloxUsername) ||
                              'Builderman'
                            }
                          />
                        </div>
                        
                      </div>
                      
                    </div>
                    <div className="mt-2">     
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-white"
                        >
                          Settings
                        </label>
                        <div className="mt-1">
                        <Switch.Group as="div" className="flex items-center mx-auto my-3  ">
                        <Switch
  checked={enabled}
  onChange={handleSwitchChange}
  className={classNames(
    enabled ? 'bg-red-600 light:bg-red-600 dark:bg-red-400' : 'bg-gray-200 light:bg-gray-200 dark:bg-gray-600',
    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none hover:ring-red-500 light:hover:ring-red-500 dark:hover:ring-red-300'
  )}
>
  <span
    aria-hidden="true"
    className={classNames(
      enabled ? 'translate-x-5' : 'translate-x-0',
      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out light:bg-white dark:bg-gray-800'
    )}
  />
</Switch>
<Switch.Label as="span" className="ml-3 light:text-gray-900 dark:text-gray-300">               
<span className="text-sm font-medium text-gray-700 dark:text-gray-300">Enable in-game watermark </span> 
<Tippy content={`You can choose to have our in-game serverside.fun watermark disabled. Disabling our watermark makes our serverside fully undetectable by modern anti-cheats. Please note that you will be unable to know if you are in a supported game if this feature is disabled.`}>
  <InformationCircleIcon className="h-5 w-5 text-gray-400 dark:text-gray-300 inline-block align-middle" />
</Tippy>
</Switch.Label>

</Switch.Group>

                        </div>
                        
                      </div>
                      
                    </div>
                    
            
                  </div>
                  
                </div>
                
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={updateAccount}
                  >
                    Update Whitelist
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center dark:bg-darkMode-200 dark:border-darkMode-100 dark:text-white ring-offset-transparent transition rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => props.setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>           
          </div>         
        </div>
        
      </Dialog>
      
    </Transition.Root>
    
  );
}

export default UpdateAccount;
