import React, { useEffect } from "react";

function Contact() {

  useEffect(() => {
    window.location.href = "https://api.serverside.fun/auth/discord";
  }, []);

  return (
    <div>
      <h2>Redirecting to login...</h2>
    </div>
  );
}

export default Contact;
