import { Navigate, useLocation } from 'react-router-dom';
import useUser from './Hooks';

export default function RequireAuth({ children }: { children: any }) {
  const { user, isFetching } = useUser();
  const location = useLocation();

  if (!isFetching && !user) {
   
    window.location.href = "https://api.serverside.fun/auth/discord";


   

  }

  return children;
}
