import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import useUser from 'lib/Hooks';
import { PlusCircleIcon, ShoppingBagIcon } from '@heroicons/react/outline';
import instance from 'lib/AxiosClient';
import { Link, useHref } from 'react-router-dom';
import { PayPalButton } from 'react-paypal-button-v2';
import { PlusIcon } from '@heroicons/react/solid';
import { useTheme } from 'lib/ThemeContext';
// import logo from '../../assets/logo.png';

function KeyModal(props: { open: any; setOpen: any; callback: any }) {
  const cancelButtonRef = useRef(null);
  const { user, isFetching } = useUser();
  const [code, setCode] = useState('');
  const { dark } = useTheme();

  const submitScript = () => {
    toast.promise(
      instance.post('/v1/redeem', {
        code,
      }),
      {
        loading: `Submitting serial key...`,
        success: (response) => {
          props.setOpen(false);
          props.callback();
          return response.data.message;
        },
        error: (err) => {
          props.setOpen(false);
          props.callback();
          return err.response.data.message;
        },
      },
      {
        style: {
          background: '#1e1e1e',
          color: '#ffffff',
        },
        success: {
          duration: 2500,
        },
      }
    );
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 z-10 ${dark ? 'dark' : ''} overflow-y-auto`}
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 dark:bg-darkMode-200 dark:bg-opacity-75 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white dark:bg-darkMode-200 border-2 border-gray-200 dark:border-darkMode-100 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 dark:bg-green-800 dark:bg-opacity-30 sm:mx-0 sm:h-10 sm:w-10">
                    <PlusIcon
                      className="h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-black dark:text-white"
                    >
                      Redeem
                    </Dialog.Title>

                    <div id="textTutorial" className=" space-y-2">
                      <p className="font-medium text-lg mt-1 dark:text-white">
                        How To Redeem
                      </p>
                      <ul className="list-decimal pl-5 text-gray-800 dark:text-white">
                        <li>Get your premium key in your email.</li>
                        <li>Enter your key in the box below.</li>
                        <li>
                          You will receive your whitelist{' '}
                          <strong>instantly</strong>
                        </li>
                      </ul>
                    </div>
                    <div className="mt-2">
                      <div className="mt-2">
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium dark:text-white"
                          >
                            Serial Key
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              onChange={(e) => setCode(e.target.value)}
                              className="shadow-sm focus:ring-blue-600 transition dark:bg-darkMode-200 dark:border-darkMode-100 focus:border-blue-600 block w-full sm:text-sm border-gray-300 rounded-md dark:text-white"
                              placeholder="ex. 5LfA28rZJpS9DxqogNhmV9z2ukwviTbh9GE6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center ring-offset-transparent rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={submitScript}
                  >
                    Submit Key
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center dark:bg-darkMode-200 dark:border-darkMode-100 dark:text-white ring-offset-transparent transition rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => props.setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default KeyModal;
