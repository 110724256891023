import React, { useEffect } from 'react';

function Contact() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      window.location.href = 'https://api.serverside.fun/v1/ref?code=' + code;
    } else {
      window.location.href = 'https://serverside.fun';
    }
  }, []);

  return (
    <div>
      <h2>Redirecting...</h2>
    </div>
  );
}

export default Contact;
