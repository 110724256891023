import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/global.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { BrowserRouter } from 'react-router-dom';

import App from './app';
import { Toaster } from 'react-hot-toast';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

AOS.init();

root.render(
  <BrowserRouter basename="/">
    <Toaster position="top-center" />
    <App />
  </BrowserRouter>,
);
