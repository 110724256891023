import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import { Turnstile } from '@marsidev/react-turnstile'
import { PlusCircleIcon } from '@heroicons/react/outline';
import instance from 'lib/AxiosClient';
import { useTheme } from 'lib/ThemeContext';
import { Switch } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/solid';
function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

function NewScriptModal(props: { open: any; setOpen: any; callback: any }) {
  const cancelButtonRef = useRef(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [script, setScript] = useState('');
  const [enabled, setEnabled] = useState(false);
  const handleSwitchChange = () => {
    setEnabled(!enabled);
  };
  const [token1, setToken] = useState()
  const { dark } = useTheme();

  const submitScript = () => {
if(!token1) return  toast.error('Please complete the captcha', {
style: {
  background: '#1e1e1e',
  color: '#ffffff',
},
});
    toast.promise(
      instance.post('/v1/private-scripts', {
        name,
        description,
        script,
        token1,
        enabled: enabled || false,
      }),
      {
        loading: `Adding script: ${name}...`,
        success: (response) => {
          props.setOpen(false);
          props.callback();
          return response.data.message;
        },
        error: (err) => {
          props.setOpen(false);
          props.callback();
          return err.response.data.message;
        },
      },
      {
        style: {
          background: '#1e1e1e',
          color: '#ffffff',
        },
        success: {
          duration: 2500,
        },
      }
    );
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 z-10 ${dark ? 'dark' : ''} overflow-y-auto`}
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 dark:bg-darkMode-200 dark:bg-opacity-75 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative dark:text-white bg-white dark:bg-darkMode-200 dark:border-darkMode-100 border-2 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 dark:bg-blue-700 dark:bg-opacity-30 sm:mx-0 sm:h-10 sm:w-10">
                    <PlusCircleIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium dark:text-white text-black"
                    >
                      Add Private Script
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-red-500">
                        Did you know if you set your script username as
                        USERNAME, it will automatically execute on your
                        currently set account?
                      </p>
                    </div>
                    <div className="mt-2">
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-white"
                        >
                          Script Name
                        </label>
                        <div className="mt-1">
                          <input
                            type="email"
                            onChange={(e) => setName(e.target.value)}
                            id="email"
                            className="shadow-sm focus:ring-blue-600 transition dark:bg-darkMode-200 dark:border-darkMode-100 focus:border-blue-600 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="My Script"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <label
                        htmlFor="comment"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Description
                      </label>
                      <div className="mt-1">
                        <textarea
                          rows={2}
                          name="comment"
                          id="comment"
                          onChange={(e) => setDescription(e.target.value)}
                          className="shadow-sm focus:ring-blue-600 transition dark:bg-darkMode-200 dark:border-darkMode-100 focus:border-blue-600 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="Cool script"
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <label
                        htmlFor="comment"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Script
                      </label>
                      <div className="mt-1">
                        <textarea
                          rows={4}
                          name="comment"
                          id="comment"
                          onChange={(e) => setScript(e.target.value)}
                          className="shadow-sm focus:ring-blue-600 transition dark:bg-darkMode-200 dark:border-darkMode-100 focus:border-blue-600 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder='require(1)("USERNAME")'
                        />
                      </div>
                    </div>
                    <div className="mt-2">     
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-white"
                        >
                          Settings
                        </label>
                        <div className="mt-1">
                        <Switch.Group as="div" className="flex items-center mx-auto my-3  ">
                        <Switch
  checked={enabled}
  onChange={handleSwitchChange}
  className={classNames(
    enabled ? 'bg-red-600 light:bg-red-600 dark:bg-red-400' : 'bg-gray-200 light:bg-gray-200 dark:bg-gray-600',
    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none hover:ring-red-500 light:hover:ring-red-500 dark:hover:ring-red-300'
  )}
>
  <span
    aria-hidden="true"
    className={classNames(
      enabled ? 'translate-x-5' : 'translate-x-0',
      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out light:bg-white dark:bg-gray-800'
    )}
  />
</Switch>
<Switch.Label as="span" className="flex items-center ml-3 light:text-gray-900 dark:text-gray-300">
  <span className="text-sm font-medium text-gray-700 dark:text-gray-400">Auto Execute</span>
  <span className="ml-1 text-red-500 text-sm font-bold">(PREMIUM)</span>
</Switch.Label>

</Switch.Group>

                        </div>
                        
                      </div>
                      
                    </div>
                    
            
                 
                  
               
                    <Turnstile
      siteKey='0x4AAAAAAACqowIIhRqnq-EZ'
      //@ts-ignore
      onSuccess={(token1) => setToken(token1)}
      options={{
        //@ts-ignore
        size: 'invisible'
      }}
      
    />
                   
                  </div>
                </div>
                <div className="sm:flex sm:flex-row-reverse -mt-8">
                  <button
                    type="button"
                    className="w-full inline-flex ring-offset-transparent transition justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={submitScript}
                  >
                    Create
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center dark:bg-darkMode-200 dark:border-darkMode-100 dark:text-white ring-offset-transparent transition rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => props.setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default NewScriptModal;
