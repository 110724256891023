import useSWR from 'swr';
import { useQuery } from 'react-query';
import instance from './AxiosClient';

export const fetcher = (url: RequestInfo) => fetch(url).then((r) => r.json());

async function getUserData() {
  const response = await instance.get('/auth/user');
  return response.data.user;
}

async function getGameData() {
  const response = await instance.get('/v1/games');
  return response.data;
}

async function getPlayerData() {
  const response = await instance.get('/v1/get-players');
  return response.data.players;
}

async function getUnique() {
  const response = await instance.get('/v1/stats');
  return response.data.cache;
}

const useUser = () => {
  const { data: user, error, isFetching } = useQuery('user', getUserData);
  return { user, error, isFetching };
};

export const useGames = () => {
  const { data: games, error, isLoading } = useQuery('games', getGameData);
  return { games, error, isLoading };
};

export const usePlayers = () => {
  const {
    data: players,
    error,
    isLoading,
  } = useQuery('players', getPlayerData, {
    refetchInterval: 5000, // Refetch every 5 secs
  });
  return { players, error, isLoading };
};

export const useUnique = () => {
  const { data: cache, error, isLoading } = useQuery('cache', getUnique);
  return { cache, error, isLoading };
};

export default useUser;
