// @ts-nocheck
import { createContext, useContext, useEffect, useState } from 'react';
import useUser from 'lib/Hooks';

const defaultContextData = {
  dark: false,
  hasThemeLoaded: false,
};

export const ThemeContext = createContext(defaultContextData);
const useTheme = () => useContext(ThemeContext);

const useEffectDarkMode = () => {
  const [themeState, setThemeState] = useState({
    dark: false,
    hasThemeLoaded: false,
  });

  const { user, isFetching: loading } = useUser();

  useEffect(() => {
    if (user && !loading) {
      setThemeState({
        ...themeState,
        dark: user.darkMode,
        hasThemeLoaded: true,
      });
    } else {
      setThemeState({ ...themeState, dark: false, hasThemeLoaded: true });
    }
  }, [user]);

  return [themeState, setThemeState];
};

function ThemeProvider({ children }) {
  const [themeState, setThemeState] = useEffectDarkMode();

  if (!themeState.hasThemeLoaded) {
    return <div />;
  }

  return (
    <div id="wrapper" className={`${themeState.dark ? 'dark' : ''}`}>
      <ThemeContext.Provider
        value={{
          dark: themeState.dark,
        }}
      >
        {children}
      </ThemeContext.Provider>
    </div>
  );
}

export { ThemeProvider, useTheme };
