import { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import useUser from 'lib/Hooks';
import { PlusCircleIcon, QuestionMarkCircleIcon, ShoppingBagIcon } from '@heroicons/react/outline';
import instance from 'lib/AxiosClient';
import { Link, useHref } from 'react-router-dom';
import { PayPalButton } from 'react-paypal-button-v2';
import { InformationCircleIcon, PlusIcon } from '@heroicons/react/solid';
import { useTheme } from 'lib/ThemeContext';
// import logo from '../../assets/logo.png';

function ReasonModal(props: { open: any; setOpen: any; callback: any ; data: any}) {
  const cancelButtonRef = useRef(null);
  const { user, isFetching } = useUser();
  const [code, setCode] = useState('');
  const { dark } = useTheme();

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 z-10 ${dark ? 'dark' : ''} overflow-y-auto`}
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 dark:bg-darkMode-200 dark:bg-opacity-75 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white dark:bg-darkMode-200 border-2 border-gray-200 dark:border-darkMode-100 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-trf-100 dark:bg-red-800 dark:bg-opacity-30 sm:mx-0 sm:h-10 sm:w-10">
                    <InformationCircleIcon
                      className="h-6 w-6 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-black dark:text-white"
                    >
                      Blacklist Info
                    </Dialog.Title>

                    <div className="mt-2">
                    <p className="text-sm text-gray-500 dark:text-gray-400">
  We regret to inform you that your access has been revoked due to a violation of our <a className="text-blue-500 hover:text-blue-700" href="https://serverside.fun/agreement.pdf">terms of service</a>. To address this issue, please contact us at <a className="text-blue-500 hover:text-blue-700" href="mailto:support@serverside.fun">support@serverside.fun</a>. We apologize for any inconvenience this may have caused.
</p>

</div>
<div className="mt-2">
  <div className="text-sm text-gray-500 dark:text-gray-300">
    <div>
    <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-white"
                        >
                          Date of Blacklist:
                        </label>
                        <span className="text-gray-500">
  {new Date(props.data.createdAt).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}
</span>

    </div>
    <div className="mt-2">
    <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700 dark:text-white"
                        >
                          Reason:
                        </label>
      {props.data && props.data.reason && props.data.reason !== "No reason provided." ? (
         <div className="text-gray-500" style={{ whiteSpace: 'pre-wrap' }}>
         {props.data.reason && props.data.reason}
       </div>
        ) : (
          <span className="text-gray-500">Unfortunately, blacklists issued before 1/31/2023 are not given a reason on-site. To ensure a clear understanding of the situation, we strongly advise you to communicate with the staff member who issued this blacklist. They will be able to provide you with further information and clarification on the matter.</span>
      )}
    </div>
  </div>
</div>
</div>

                    
                 
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                 
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center dark:bg-darkMode-200 dark:border-darkMode-100 dark:text-white ring-offset-transparent transition rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => props.setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ReasonModal;
