import { Dialog } from '@headlessui/react';
import { Link } from 'react-router-dom';
import useUser from 'lib/Hooks';
import { useState } from 'react';
import { DotsHorizontalIcon } from '@heroicons/react/outline';
import { MenuAlt4Icon, XIcon } from '@heroicons/react/solid';
import { classNames } from 'lib/CustomFunctions';
import SvgComponent from 'assets/BrandLogo';
const navigation = [
  { name: 'Home', href: '/' },
  { name: 'FAQ', href: '/#faq' },
  { name: 'Pricing', href: '/pricing' },
  { name: 'Showcases', href: '/showcases' },
  { name: 'Discord', href: 'https://serverside.fun/discord' },
];

function NewNav(props: any) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { user, isFetching: loading } = useUser();

  return (
    <div className="bg-darkMode-200 isolate">
      <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
        <svg
          className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#fc8989" />
              <stop offset={1} stopColor="#f70707" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="absolute lg:mt-96 inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-66rem)]">
        <svg
          className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#fc8989" />
              <stop offset={1} stopColor="#f70707" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="px-6 pt-6 fixed top-0 w-full inset-x-0 lg:px-8 backdrop-blur-lg backdrop-filter bg-red z-10 py-4 ">
        <nav className="flex items-center justify-between" aria-label="Global">
          <div
            className="flex items-center lg:min-w-0 lg:flex-1"
            aria-label="Global"
          >
            <Link
              to="/"
              className="scale-100 transition hover:scale-[102%] active:scale-[95%]"
            >
              <SvgComponent className="h-[3rem] mt-2" />
            </Link>
          </div>

          <div className="flex items-center lg:hidden z-10">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <MenuAlt4Icon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:min-w-0 lg:flex-1 lg:justify-center lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                target={item.name == 'Discord' ? '_blank' : ''}
                className={classNames(
                  window.location.pathname == item.href
                    ? 'text-red-500'
                    : 'text-white',
                  'font-semibold hover:text-red-500 transition',
                )}
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex items-center lg:min-w-0 lg:flex-1 lg:justify-end">
            <div className="flex gap-x-7 sm:justify-center">
              <div className="relative group">
                <div className="absolute -inset-0.5 bg-gradient-to-r from-red-500 to-orange-400 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
                {user && !loading ? (
                  <Link
                    to={'/user/dashboard'}
                    className="relative font-semibold px-7 py-3 active:scale-[95%] transition scale-100 bg-darkMode-300 rounded-lg leading-none flex items-center divide-x divide-gray-600"
                  >
                    <span className="flex items-center space-x-5">
                      <span className="text-gray-100">Dashboard</span>
                    </span>
                  </Link>
                ) : (
                  <a
                    href={`${process.env.REACT_APP_API_URL}/auth/discord`}
                    className="relative font-semibold px-7 py-3 active:scale-[95%] transition scale-100 bg-darkMode-300 rounded-lg leading-none flex items-center divide-x divide-gray-600"
                  >
                    <span className="flex items-center space-x-5">
                      <span className="text-gray-100">Sign In</span>
                    </span>
                  </a>
                )}
              </div>
            </div>
          </div>
        </nav>
        <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <Dialog.Panel className="fixed inset-0 overflow-y-auto bg-darkMode-200 right-0 px-6 py-6 lg:hidden">
            <div className="flex h-9 items-center justify-between">
              <div className="flex">
                <div
                  className="flex items-center lg:min-w-0 lg:flex-1"
                  aria-label="Global"
                >
                  <Link
                    to="/"
                    className="scale-100 transition hover:scale-[102%] active:scale-[95%]"
                  >
                    <SvgComponent className="h-[3rem]" />
                  </Link>
                </div>
              </div>
              <div className="flex">
                <button
                  type="button"
                  className=" -m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white relative"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-white transition hover:bg-gray-400/10"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  {user ? (
                    <Link
                      to={'/user/dashboard'}
                      className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-white transition hover:bg-gray-400/10"
                    >
                      Dashboard
                    </Link>
                  ) : (
                    <a
                      href={`${process.env.REACT_APP_API_URL}/auth/discord`}
                      className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-white transition hover:bg-gray-400/10"
                    >
                      Sign In
                    </a>
                  )}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </div>
      <div className="pt-16 sm:pt-20" />
      <main>
        <div className="relative px-6 lg:px-8">
          <div className="mx-auto text-white pt-20 pb-32  sm:pb-40">
            <div>
              <div id="content">
                {/* content here */}
                {props?.children}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default NewNav;
