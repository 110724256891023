import { ChevronRightIcon, HomeIcon } from '@heroicons/react/outline';
import Footer from '../components/Footer';
import NewNav from 'components/NewNav';
import { CashIcon, SupportIcon } from '@heroicons/react/solid';
import { useEffect } from 'react';

const links = [
  {
    title: 'Home',
    description: 'Visit our main page',
    href: '/',
    icon: HomeIcon,
  },
  {
    title: 'Pricing',
    description: 'View our pricing plans',
    href: '/pricing',
    icon: CashIcon,
  },
  {
    title: 'Discord',
    description: 'Join our community',
    href: '/discord',
    icon: SupportIcon,
  },
];

export default function ERROR() {
  useEffect(() => {
    // redirect to https://dashboard.serverside.fun/user/dashboard
    window.location.href = 'https://dashboard.serverside.fun/user/dashboard';
  }, []);
  return (
    <>
      <NewNav>
        <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 pt-16"></div>
          <div className="max-w-xl mx-auto py-16 sm:py-24">
            <div className="text-center">
              <h1
                data-aos="fade-up"
                className="bg-gradient-to-r from-red-400 to-red-600 bg-clip-text text-transparent text-4xl font-bold  sm:text-center sm:text-6xl"
              >
                404 Error
              </h1>
              <p
                data-aos="fade-up"
                className="mt-6 text-lg font-medium leading-8 text-gray-500 sm:text-center"
              >
                The page you are looking for cannot be found.
              </p>
            </div>
            <div className="mt-12">
              <h2 className="text-sm font-semibold text-white tracking-wide uppercase">
                Popular pages
              </h2>
              <ul
                role="list"
                className="mt-4 border-t border-b border-darkMode-500 divide-y divide-darkMode-500"
              >
                {links.map((link, linkIdx) => (
                  <li
                    key={linkIdx}
                    className="relative py-6 flex items-start space-x-4"
                  >
                    <div className="flex-shrink-0">
                      <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-red-800/20">
                        <link.icon
                          className="h-6 w-6 text-red-500"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1">
                      <h3 className="text-base font-medium text-white">
                        <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-red-500">
                          <a href={link.href} className="focus:outline-none">
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            {link.title}
                          </a>
                        </span>
                      </h3>
                      <p className="text-base text-gray-500">
                        {link.description}
                      </p>
                    </div>
                    <div className="flex-shrink-0 self-center">
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </main>
      </NewNav>
      <Footer overrideDark />
    </>
  );
}
