import {
  ClipboardIcon,
  StarIcon,
  UserCircleIcon,
  UsersIcon,
  XCircleIcon,
} from '@heroicons/react/outline';
import {
  CheckCircleIcon,
  ShieldCheckIcon,
  SwitchVerticalIcon,
  PencilAltIcon,
  CreditCardIcon,
  UserGroupIcon,
  UserIcon,
  ClipboardCopyIcon,
  ClipboardListIcon,
  PlusIcon,
  LightningBoltIcon,
} from '@heroicons/react/solid';
import useUser from 'lib/Hooks';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Route } from 'react-router-dom';
import UpdateAccount from './Modals/UpdateAccount';
import RedeemModal from 'components/Modals/RedeemModal';
import PurchaseModal from 'components/Modals/PurchaseModal';
import UpgradeModal from 'components/Modals/UpgradeModal';
import PartnerModal from 'components/Modals/PartnerModal';
import instance from 'lib/AxiosClient';
import Partner from 'components/Modals/PartnerModal';
import { classNames } from 'lib/CustomFunctions';
export default function PageHeader() {
  const currentHour = new Date().getHours();
  const [open, setOpen] = useState(false);
  const { user, isFetching } = useUser();

  let timestamp;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);
  const [loading, setLoading] = useState(false);
  if (currentHour < 12) {
    timestamp = 'morning';
  } else if (currentHour < 18) {
    timestamp = 'afternoon';
  } else {
    timestamp = 'evening';
  }
  const handleTrial = async () => {
    setLoading(true);

    await toast.promise(
      instance.post('/v1/toggle-free', {
        userId: user.id,
      }),
      {
        loading: `Activating free version...`,
        success: (response: any) => {
          setLoading(false);
          // refresh the page
          window.location.reload();
          return response.data.message;
        },
        error: (err: any) => {
          setLoading(false);
          return err.response.data.message;
        },
      },
      {
        style: {
          background: '#1e1e1e',
          color: '#ffffff',
        },
      },
    );
  };
  const handleBuyers = async () => {
    setLoading(true);

    await toast.promise(
      instance.post('/v1/buyers', {
        userId: user.id,
      }),
      {
        loading: `Fetching invite...`,
        success: (response: any) => {
          setLoading(false);
          window.open(response.data.url, '_blank');
          return response.data.message;
        },
        error: (err: any) => {
          setLoading(false);
          return err.response.data.message;
        },
      },
      {
        style: {
          background: '#1e1e1e',
          color: '#ffffff',
        },
        success: {
          duration: 2500,
        },
      },
    );
  };
  const handleCancel = async () => {
    setLoading(true);

    await toast.promise(
      instance.post('/v1/toggle-cancel', {
        userId: user.id,
      }),
      {
        loading: `Cancelling free version...`,
        success: (response: any) => {
          setLoading(false);
          // refresh the page
          window.location.reload();
          return response.data.message;
        },
        error: (err: any) => {
          setLoading(false);
          return err.response.data.message;
        },
      },
      {
        style: {
          background: '#1e1e1e',
          color: '#ffffff',
        },
        success: {
          duration: 2500,
        },
      },
    );
  };
  return (
    <>
      <RedeemModal
        open={modalOpen}
        setOpen={setModalOpen}
        callback={() => {}}
      />
      <PurchaseModal
        open={modalOpen3}
        setOpen={setModalOpen3}
        callback={() => {}}
      />
      <PurchaseModal
        open={modalOpen2}
        setOpen={setModalOpen2}
        callback={() => {}}
      />
      <Partner open={modalOpen4} setOpen={setModalOpen4} callback={() => {}} />
      <UpdateAccount open={open} setOpen={setOpen} callback={() => {}} />
      <div className="dark:bg-darkMode-200 shadow dark:border-b border-gray-200 dark:border-darkMode-100">
        <div className="lg:max-w-[90rem] lg:mx-auto">
          <div className="p-6 md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              {/* Profile */}
              <div className="flex items-center">
                <img
                  className="hidden h-16 w-16 skeletonDark rounded-full sm:block"
                  src={
                    user
                      ? user.profilePicture
                      : 'https://serverside.fun/placeholder.png'
                  }
                  alt=""
                />
                <div>
                  <div className="flex items-center">
                    <img
                      className="h-16 w-16 rounded-full skeletonDark sm:hidden"
                      src={
                        user
                          ? user.profilePicture
                          : 'https://serverside.fun/placeholder.png'
                      }
                      alt=""
                    />
                    <h1
                      className={classNames(
                        !user ? 'skeletonDark rounded' : '',
                        'ml-3 text-2xl dark:text-white font-semibold leading-7 text-gray-900 sm:leading-9 sm:truncate',
                      )}
                    >
                      Good {timestamp}, {user ? user.username : 'No User'}.
                    </h1>
                  </div>
                  <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    {user && (
                      <dd className="mt-3 flex items-center text-sm dark:text-white font-medium sm:mr-6 sm:mt-0 capitalize">
                        <UserIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-blue-400" />
                        User
                      </dd>
                    )}
                    {user && user.buyer && (
                      <dd className="mt-3 flex items-center text-sm dark:text-white font-medium sm:mr-6 sm:mt-0 capitalize">
                        <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" />
                        Buyer
                      </dd>
                    )}

                    {user && user.permission >= 2 && (
                      <dd className="mt-3 flex items-center text-sm dark:text-white font-medium sm:mr-6 sm:mt-0 capitalize">
                        <ShieldCheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400" />
                        Staff
                      </dd>
                    )}

                    {user && user.permission >= 3 && (
                      <dd className="mt-3 flex items-center text-sm dark:text-white font-medium sm:mr-6 sm:mt-0 capitalize">
                        <UserGroupIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-blue-400" />
                        Administrator
                      </dd>
                    )}

                    {user && user.permission >= 4 && (
                      <dd className="mt-3 flex items-center text-sm dark:text-white font-medium sm:mr-6 sm:mt-0 capitalize">
                        <ShieldCheckIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" />
                        Owner
                      </dd>
                    )}
                  </dl>
                </div>
              </div>
            </div>
            <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4 ">
              {/* {user && !user.buyer && (
                <button
                  type="button"
                  className="transition dark:focus-within:ring-offset-dModeDark inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 dark:focus:ring-red-500"
                  onClick={() => {}}
                >
                  Start Free Trial
                </button>
              )} */}
              {user && !user.buyer && (
                <button
                  type="button"
                  className="transition ring-offset-transparent dark:bg-darkMode-200 dark:border-darkMode-100 dark:text-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 dark:focus:ring-red-500"
                  onClick={() => setModalOpen2(true)}
                >
                  <CreditCardIcon
                    className="-ml-0.5 mr-2 h-4 w-4 text-green-600"
                    aria-hidden="true"
                  />
                  Purchase Whitelist
                </button>
              )}
              {user && !user.buyer && !user && (
                <button
                  type="button"
                  className="transition ring-offset-transparent dark:bg-darkMode-200 dark:border-darkMode-100 dark:text-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 dark:focus:ring-red-500"
                  onClick={() => setModalOpen(true)}
                >
                  <PlusIcon
                    className="-ml-0.5 mr-2 h-4 w-4 text-green-600"
                    aria-hidden="true"
                  />
                  Redeem Key
                </button>
              )}
              {user && user.buyer && user.buyer.type === 'NORMAL' && (
                <button
                  type="button"
                  className="transition ring-offset-transparent dark:bg-darkMode-200 dark:border-darkMode-100 dark:text-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 dark:focus:ring-red-500"
                  onClick={() => setModalOpen3(true)}
                >
                  <CreditCardIcon
                    className="-ml-0.5 mr-2 h-4 w-4 text-green-600"
                    aria-hidden="true"
                  />
                  Upgrade
                </button>
              )}
              {user && ((user.buyer && user.buyer.partner === true) || user.partner === true) && (                
                <button
                  type="button"
                  className="transition ring-offset-transparent dark:bg-darkMode-200 dark:border-darkMode-100 dark:text-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 dark:focus:ring-red-500"
                  onClick={() => setModalOpen4(true)}
                >
                  <LightningBoltIcon
                    className="-ml-0.5 mr-2 h-4 w-4 text-green-600"
                    aria-hidden="true"
                  />
                  Partner
                </button>
              )}
              {user && !user.buyer && !user.trial && (
                <button
                  type="button"
                  className="transition ring-offset-transparent dark:bg-darkMode-200 dark:border-darkMode-100 dark:text-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 dark:focus:ring-red-500"
                  onClick={handleTrial}
                >
                  <StarIcon
                    className="-ml-0.5 mr-2 h-4 w-4 text-green-600"
                    aria-hidden="true"
                  />
                  Free Version
                </button>
              )}

              {user && user.buyer && (
                <button
                  type="button"
                  className="transition ring-offset-transparent dark:bg-darkMode-200 dark:border-darkMode-100 dark:text-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 dark:focus:ring-red-500"
                  onClick={handleBuyers}
                >
                  <UserGroupIcon
                    className="-ml-0.5 mr-2 h-4 w-4 text-green-500"
                    aria-hidden="true"
                  />
                  Buyers Server
                </button>
              )}
              {user && (user.buyer || user.trial) && (
                <button
                  type="button"
                  onClick={() => setOpen(true)}
                  className="transition ring-offset-transparent dark:bg-darkMode-200 dark:border-darkMode-100 dark:text-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 dark:focus:ring-red-500"
                >
                  <PencilAltIcon
                    className="-ml-0.5 mr-2 h-4 w-4 text-green-500"
                    aria-hidden="true"
                  />
                  Manage Whitelist
                </button>
              )}
              {user && user.trial && (
                <button
                  type="button"
                  className="transition ring-offset-transparent dark:bg-darkMode-200 dark:border-darkMode-100 dark:text-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 dark:focus:ring-red-500"
                  onClick={handleCancel}
                >
                  <XCircleIcon
                    className="-ml-0.5 mr-2 h-4 w-4 text-red-600"
                    aria-hidden="true"
                  />
                  Cancel Free Version
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
