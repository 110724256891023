//@ts-nocheck
import SideNavContainer from 'components/SideNavContainer';
import FadeIn from 'react-fade-in';
import { CodeIcon, UserIcon } from '@heroicons/react/solid';
import { classNames } from 'lib/CustomFunctions';
import { useEffect, useState } from 'react';
import Pagination from 'components/Pagination';
import useUser from 'lib/Hooks';
import instance from 'lib/AxiosClient';
import { FingerPrintIcon } from '@heroicons/react/outline';
import ReasonModal from 'components/Modals/ReasonModal';
const pageSize = 4;

export default function Dashboard() {
  const [news, setNews] = useState([]);
  const [loadingNews, setLoadingNews] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [activity, setActivity] = useState([]);
  const [data, setData] = useState([]);
  const [currentActivity, setCurrentActivity] = useState([]);
  const [loadingActivity, setLoadingActivity] = useState(true);
  const { user } = useUser();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalItems, setTotalItems] = useState(0);
  const [fetchingActivity, setFetchingActivity] = useState(false);

 


  const stats = [
    {
      id: 1,
      name: 'Whitelist Type',
      stat:
        user && user.buyer && user.buyer.type
          ? user.buyer.type === 'PLUS'
            ? 'Plus'
            : user.buyer.type.charAt(0).toUpperCase() +
              user.buyer.type.slice(1).toLowerCase()
          : user && user.trial
          ? 'Free Plan'
          : 'None' ?? 'not avail',
      icon: CodeIcon,
      change: '122',
      changeType: 'increase',
    },
    {
      id: 2,
      name: 'Roblox Username',
      stat:
        (user && user.buyer && user.buyer.robloxUsername) ||
        (user && user.trial && user.trial.robloxUsername) ||
        'N/A',
      icon: UserIcon,
      change: '5',
      changeType: 'increase',
    },
    {
      id: 3,
      name: 'User ID',
      stat: user && user.id,
      icon: FingerPrintIcon,
      change: '3.2%',
      changeType: 'decrease',
    },
  ];

  useEffect(() => {
    instance.get('/v1/get-news').then((res) => {
      setNews(res.data.news);
      setLoadingNews(false);
    });

  }, []);
  useEffect(() => {
    // If a fetch is already in progress, return from this function without doing anything
    if (fetchingActivity) return;
    setActivity([]);
    setFetchingActivity(true);
    instance.get(`/v1/get-activity?page=${currentPage}`).then((res) => {
      setActivity(res.data.activity);
      setCurrentActivity(res.data.activity);
      setCurrentPage(res.data.currentPage);
      setTotalPages(res.data.totalPages);
      setTotalItems(res.data.totalCount);
      setLoadingActivity(false);
      setFetchingActivity(false);
    });
  }, [currentPage]);

  const debouncedSetCurrentPage = (page) => {
    if (!fetchingActivity) setCurrentPage(page);
  };
  return (
    <SideNavContainer header title="Dashboard">
      <ReasonModal
        open={modalOpen}
        setOpen={setModalOpen}
        callback={() => {}}
        data={data}
      />
      <FadeIn delay={50}>
        <div>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {stats.map((item) => (
              <div
                key={item.id}
                className="relative pt-5 px-4 pb-6 border border-gray-200 dark:border-darkMode-100 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
              >
                <dt>
                  <div className="absolute bg-red-500 rounded-md p-3">
                    <item.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                    {item.name}
                  </p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p
                    className={classNames(
                      !user ? 'skeletonDark rounded' : '',
                      'text-xl dark:text-white font-semibold text-gray-900',
                    )}
                  >
                    {item.stat ?? 'not avail'}
                  </p>
                  <p
                    className={classNames(
                      item.changeType === 'increase'
                        ? 'text-green-600'
                        : 'text-red-600',
                      'ml-2 flex items-baseline text-sm font-semibold',
                    )}
                  />
                </dd>
              </div>
            ))}
          </dl>
        </div>

        <div
          id="content"
          className="grid mt-4 gap-3 grid-cols-1 sm:grid-cols-2"
        >
          <div
            id="activityCard"
            className="border dark:border-darkMode-100 dark:text-white rounded-md shadow"
          >
            <p className="font-medium text-lg p-3">Activity</p>
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
                  <table className="min-w-full divide-y mb-1 border-t border-gray-300 dark:divide-darkMode-100 dark:border-darkMode-100 divide-gray-300">
                    <thead className="bg-gray-50 dark:bg-darkMode-200 dark:text-white">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6"
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold"
                        >
                          Activity History
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold"
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-darkMode-100">
                      {activity.length > 0 ? (
                        currentActivity.map((person, idx) => (
                          <tr key={person.email}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                              {person.id}
                            </td>
                            <td className="whitespace-wrap px-3 py-4 text-sm text-gray-500 dark:text-gray-500">
  {person.action.includes("Blacklisted by") ? (
    <div className="flex items-center">
      <span>{person.action}</span>
     
    </div>
  ) : (
    <span>{person.action}</span>
  )}
</td>


<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-white">
  {new Date(person.createdAt).toLocaleDateString()}
</td>
<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
  {person.action.includes("Blacklisted by") ? (
    <div className="inline-flex items-center">
      <button
        className="ml-2 hover:scale-[106%] inline-flex items-center transition px-2.5 py-0.5 rounded-md text-xs font-medium bg-red-100 text-red-800 dark:text-red-500 dark:bg-red-700 dark:bg-opacity-30 border-2 border-red-500 hover:bg-red-500 hover:text-white"
        onClick={() => {
          setData(person);
          setModalOpen(true);
        }}
      >
          View 
      </button>
    </div>
  ) : (
    <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-green-100 text-green-800 dark:text-green-500 dark:bg-green-700 dark:bg-opacity-30 border-2 border-green-500">
      {person.status}
    </span>
  )}
</td>

                          </tr>
                        ))
                      ) : currentActivity ? (
                        Array.from(Array(4).keys()).map((i) => (
                          // skeleton using skeletonDark class
                          <tr className="dark:text-white">
                            <td className="py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                              <div className="skeletonDark rounded">1234</div>
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 dark:text-gray-500">
                              <div className="skeletonDark rounded">
                                dwadwadwa
                              </div>
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500 dark:text-white">
                              <div className="skeletonDark rounded">dwadwa</div>
                            </td>
                            <td className="px-3 py-4 text-sm text-gray-500">
                              <div className="skeletonDark rounded">dwadad</div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="dark:text-white">
                          <td
                            aria-colspan={9}
                            colSpan={9}
                            className={classNames(
                              loadingActivity ? 'animate-pulse' : '',
                              'whitespace-nowrap px-6 py-4 text-center text-sm font-medium text-gray-500',
                            )}
                          >
                            <div>
                              {loadingActivity
                                ? 'Loading activity log...'
                                : 'No activity logs found.'}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <Pagination
      onPageChange={debouncedSetCurrentPage}
      currentPage={currentPage}
      totalCount={totalItems}
      pageSize={pageSize}
    />
                </div>
              </div>
            </div>
          </div>

          <div
            id="newsCard"
            className="border p-3 rounded-md shadow max-h-96 dark:border-darkMode-100 overflow-scroll"
          >
            <p className="font-medium text-lg dark:text-white">News</p>

            <div id="newsContainer" className="mt-2 space-y-2">
              {!loadingNews
                ? news.map((news, idx) => (
                    <div
                      id="newsCard"
                      className="bg-gray-50 dark:bg-transparent p-2 rounded shadow border dark:border-darkMode-100"
                    >
                      <p
                        id="title"
                        className="text-md dark:text-white font-medium"
                      >
                        {news.title}
                      </p>
                      <p className="text-xs flex text-gray-500 dark:text-gray-500">
                        <UserIcon className="w-3 mr-0.5" />{' '}
                        <span className="mr-1 text-blue-600">
                          {news.author}
                        </span>
                        {new Date(news.createdAt).toLocaleString()}
                      </p>

                      <div
                        id="content"
                        className="mt-2 grid grid-cols-1 gap-2 sm:grid-cols-2"
                      >
                        <div>
                          <img
                            src={news.imageURL}
                            className=" rounded max-w-full max-h-full"
                            alt="news"
                          />
                        </div>
                        <div>
                          <p className="text-sm w-full dark:text-white">
                            {news.content}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                : Array.from({ length: 5 }).map((_, idx) => (
                    <div
                      id="newsCard"
                      className="bg-gray-50 dark:bg-transparent p-2 rounded shadow border dark:border-darkMode-100"
                    >
                      <p
                        id="title"
                        className="text-md skeletonDark rounded w-1/4 dark:text-white font-medium"
                      >
                        news title lole
                      </p>
                      <p className="text-xs mt-1 w-[9rem] flex skeletonDark rounded space-x-4 text-gray-500 dark:text-gray-500">
                        <UserIcon className="w-3 mr-0.5" />{' '}
                      </p>

                      <div
                        id="content"
                        className="mt-2 grid grid-cols-1 gap-2 sm:grid-cols-2"
                      >
                        <div className="skeletonDark rounded "></div>
                        <div>
                          <p className="text-sm w-full skeletonDark rounded dark:text-white">
                            lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nulla vitae elit libero, a pharetra augue.
                            Nullam quis risus eget urna mollis ornare vel eu
                            leo. Nullam id dolor id nibh ultricies vehicula ut
                            id elit. Donec
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </FadeIn>
    </SideNavContainer>
  );
}
