import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import { CheckCircleIcon } from '@heroicons/react/solid';

import useUser from 'lib/Hooks';
import {
  CurrencyDollarIcon,
  PlusCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline';
import instance from 'lib/AxiosClient';
import { useTheme } from 'lib/ThemeContext';
import { Switch } from '@headlessui/react';
import Tippy from '@tippyjs/react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { PuffLoader } from 'react-spinners';

import {
  InformationCircleIcon,
  LightningBoltIcon,
} from '@heroicons/react/solid';
interface PartnerData {
  id: number;
  userId: number;
  refcode: string;
  clicks: number;
  revenue: number;
  buyers: number;
  registered: number;
  pending: number;
  cpm: number;
  createdAt: string;
}
interface Stats {
  name: string;
  stat: string | number;
  unit: string;
}
function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}
let code = 'error';

function UpdateAccount(props: { open: any; setOpen: any; callback: any }) {
  const cancelButtonRef = useRef(null);
  const [username, setUsername] = useState('');
  const { user, isFetching } = useUser();
  const { dark } = useTheme();
  const [chartData, setChartData] = useState(null);
  const [year, setYear] = useState(0);
  const [selectedTimeRange, setSelectedTimeRange] = useState('1D');
  const [isLoading, setIsLoading] = useState(false);
  const [chartLoaded, setChartLoaded] = useState(false);

  const [stats, setStats] = useState<Stats[]>([]);
  const [partnerData, setPartnerData] = useState<PartnerData>({
    id: 0,
    userId: 0,
    refcode: '',
    clicks: 0,
    revenue: 0,
    buyers: 0,
    registered: 0,
    pending: 0,
    cpm: 0,
    createdAt: '',
  });

  useEffect(() => {
    if (!isFetching && user && ((user.buyer && user.buyer.partner) || user.partner)) {
        instance
        .get('/v1/partner/')
        .then((response) => {
          setPartnerData(response.data.partner);
          code = response.data.partner.refcode;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isFetching]);
  useEffect(() => {
    if (
      !isFetching &&
      user &&
      ((user.buyer && user.buyer.partner) || user.partner) &&
      !chartLoaded
    ) {
      setIsLoading(true);
      instance
        .get('/v1/partner/chart')
        .then((response) => {
          setChartData(response.data);
          setIsLoading(false);
          setChartLoaded(true);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [isFetching]);

  useEffect(() => {
    setStats([
      {
        name: 'Unique Clicks',
        stat: partnerData.clicks.toLocaleString(),
        unit: `CPM: $${partnerData.cpm}`,
      },
      {
        name: 'Revenue',
        stat: `$${partnerData.revenue.toLocaleString()}`,
        unit: `Pending: $${partnerData.pending.toLocaleString()}`,
      },
      {
        name: 'Total Buyers',
        stat: partnerData.buyers.toLocaleString(),
        unit: `Registered: ${partnerData.registered.toLocaleString()}`,
      },
    ]);
  }, [partnerData]);
  async function requestPayout() {
    await toast.promise(
      instance.post('/v1/partner/payout', {}),
      {
        loading: `Requesting payout..`,
        success: (response: any) => {
          return response.data.message;
        },
        error: (err: any) => {
          return err.response.data.message;
        },
      },
      {
        style: {
          background: '#1e1e1e',
          color: '#ffffff',
        },
        duration: 7500,
      },
    );
  }
  const changeDate = async (date: string) => {
    setSelectedTimeRange(date);

    const tabs = [
      { id: '1D', label: 'Today' },
      { id: '1W', label: 'Week' },
      { id: '1M', label: 'Month' },
      { id: '1Y', label: 'Year' },
    ];

    const index = tabs.findIndex((tab) => tab.id === date);
    if (index !== -1) {
      setYear(index);
    } else {
      console.error('Invalid date filter provided:', date);
    }
  };
  const [enabled, setEnabled] = useState(false);
  //@ts-ignore
  function getStartOfWeek(date, startDay = 0) {
    // startDay = 0 is Sunday, startDay = 1 is Monday
    const day = date.getDay();
    const diff = (day - startDay + 7) % 7;
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - diff);
    startOfWeek.setHours(0, 0, 0, 0);
    return startOfWeek;
  }
  //@ts-ignore

  const getXAxisConfig = (selectedTimeRange) => {
    const today = new Date();
    const now = new Date(
      Date.UTC(
        new Date().getUTCFullYear(),
        new Date().getUTCMonth(),
        new Date().getUTCDate(),
        new Date().getUTCHours(),
        new Date().getUTCMinutes(),
        new Date().getUTCSeconds(),
      ),
    );

    switch (selectedTimeRange) {
      case '1D':
        const endOfDay = new Date(now);
        return {
          tickInterval: 3600 * 1000,
          labels: {
            format: '{value:%l %p}',
            //@ts-ignore
            formatter: function () {
              return Highcharts.dateFormat(
                '%l %p',
                //@ts-ignore
                this.value + -new Date().getTimezoneOffset() * 60 * 1000,
              );
            },
          },
          pointInterval: 3600 * 1000,
          pointStart: endOfDay.getTime() - 24 * 3600 * 1000, // 24 hours earlier
        };

      case '1W':
        const endOfWeek = new Date(now);
        return {
          tickInterval: 24 * 3600 * 1000,
          labels: {
            format: '{value:%a}',
            //@ts-ignore
            formatter: function () {
              return Highcharts.dateFormat(
                '%a',
                //@ts-ignore
                this.value + -new Date().getTimezoneOffset() * 60 * 1000,
              );
            },
          },
          pointInterval: 24 * 3600 * 1000,
          pointStart: endOfWeek.getTime() - 7 * 24 * 3600 * 1000, // 7 days earlier
        };

      case '1M':
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        return {
          tickInterval: 24 * 3600 * 1000,
          labels: {
            format: '{value:%e %b}',
          },
          pointInterval: 24 * 3600 * 1000,
          pointStart: startOfMonth.getTime(),
        };

      case '1Y':
        const startOfYear = new Date(today.getFullYear(), 0, 1); // Gets the start of the year
        return {
          tickInterval: 30 * 24 * 3600 * 1000, // approx. one month
          labels: {
            format: '{value:%b}',
          },
          pointInterval: 30 * 24 * 3600 * 1000,
          pointStart: startOfYear.getTime(),
        };

      default:
        console.error('Invalid date filter:', selectedTimeRange);
        break;
    }
  };
  Highcharts.setOptions({
    time: {
      useUTC: false,
    },
  });
  const config = getXAxisConfig(selectedTimeRange);
  const options = {
    chart: {
      type: 'spline',
      height: 250,
      backgroundColor: 'transparent',
    },
    title: {
      text: null,
      align: 'left',
    },
    tooltip: {
      shared: true,
      backgroundColor: '#1c1c1c',
      style: {
        color: '#f0f0f0', // This will set the tooltip text color to white
      },
    },

    subtitle: {
      text: null,
      align: 'left',
    },
    yAxis: {
      title: {
        text: null,
      },
      gridLineWidth: 1, // Ensure grid lines are shown
      gridLineDashStyle: 'Dash', // This makes the grid lines dotted
      gridLineColor: '#2d2d2d', // A light grey
      labels: {
        style: {
          color: '#9e9e9e',
        },
      },
    },
    credits: {
      enabled: false,
    },

    xAxis: {
      type: 'datetime',
      //@ts-ignore
      tickInterval: config.tickInterval,
      labels: {
        //@ts-ignore
        format: config.labels.format,

        style: {
          color: '#9e9e9e',
        },
      },
      lineColor: '#2d2d2d',
      tickWidth: 0,
    },

    legend: {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'top',
      itemStyle: {
        color: '#9e9e9e', // This changes the color of legend labels
      },
      itemHoverStyle: {
        color: '#7d7d7d', // This changes the color of legend labels on hover
      },
    },
    plotOptions: {
      series: {
        //@ts-ignore
        pointInterval: config.pointInterval,
        //@ts-ignore
        pointStart: config.pointStart,
        label: {
          connectorAllowed: false,
        },
        lineWidth: 2,
        marker: {
          enabled: false, // hides the marker by default
          symbol: 'circle', // Specifies the shape of the marker. 'circle' is for dots.
          states: {
            hover: {
              enabled: true,
              radius: 4, // Adjust this value for the size of the dot on hover
            },
          },
        },
      },
    },
    series: [
      {
        name: 'Unique Clicks',
        color: '#f5cc5d',
        //@ts-ignore
        data: chartData?.series[0]?.data[year],
      },
      {
        name: 'Earnings',
        color: '#675df5',
        //@ts-ignore
        data: chartData?.series[1]?.data[year],
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  };

  // this will cause the effect to run whenever isFetching changes
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed inset-0 z-10 ${dark ? 'dark' : ''} overflow-y-auto`}
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 dark:bg-darkMode-200 dark:bg-opacity-75 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white dark:bg-darkMode-200 border-2 border-gray-200 dark:border-darkMode-100 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 dark:bg-green-800 dark:bg-opacity-30 sm:mx-0 sm:h-10 sm:w-10">
                    <LightningBoltIcon
                      className="h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-black dark:text-white"
                    >
                      Partner Analytics
                    </Dialog.Title>

                    <div className="mt-1 text-sm font-medium text-gray-500 dark:text-gray-400">
                      Referral Link:{' '}
                      <a
                        className="text-blue-500 hover:text-blue-800 cursor-pointer"
                        href="https://serverside.fun"
                      >
                        https://serverside.fun/ref?code={code}
                      </a>
                    </div>
                  </div>
                </div>

                <dl className="mt-4 grid grid-cols-1 gap-5 sm:grid-cols-3">
                  {stats.map((item) => (
                    <div
                      key={item.name}
                      className="overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-5 dark:bg-darkMode-200 dark:shadow-darkMode-100 dark:border dark:border-darkMode-100 shadow border"
                    >
                      <dt className="truncate text-sm font-medium text-gray-500 dark:text-gray-300">
                        {item.name}
                      </dt>
                      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-gray-400">
                        {item.stat}
                      </dd>
                      {item.unit && (
                        <dd className="mt-1 text-xs font-medium text-gray-500 dark:text-gray-500">
                          {item.unit}
                        </dd>
                      )}
                    </div>
                  ))}
                </dl>

                <div className="mt-5">
                  <div className="flex justify-between items-center">
                    {/* Left aligned button */}
                    <button
                      type="button"
                      onClick={requestPayout}
                      className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus:outline-none  active:scale-95 transition-transform duration-200 ease-in-out"
                    >
                      <CurrencyDollarIcon
                        className="-ml-0.5 h-5 w-5"
                        aria-hidden="true"
                      />
                      Request Payout
                    </button>

                    {/* Right aligned buttons */}
                    <span className="isolate inline-flex rounded-md shadow-sm">
                      {/* The 1D Button */}
                      <button
                        type="button"
                        onClick={() => changeDate('1D')}
                        className={`relative inline-flex items-center px-3 py-1 text-sm font-semibold 
                    ${
                      selectedTimeRange === '1D'
                        ? 'bg-blue-600 text-white'
                        : 'dark:bg-darkMode-200 dark:text-gray-400 hover:bg-gray-50 hover:dark:bg-darkMode-100'
                    } 
                    dark:ring-darkMode-100 ring-1 ring-gray-300 dark:hover:border-darkMode-150 focus:z-10 border border-gray-300 dark:border-darkMode-100 rounded-l-md`}
                      >
                        1D
                      </button>

                      {/* The 1W Button */}
                      <button
                        type="button"
                        onClick={() => changeDate('1W')}
                        className={`relative -ml-px inline-flex items-center px-3 py-1 text-sm font-semibold 
                    ${
                      selectedTimeRange === '1W'
                        ? 'bg-blue-600 text-white'
                        : 'dark:bg-darkMode-200 dark:text-gray-400 hover:bg-gray-50 hover:dark:bg-darkMode-100'
                    }
                    dark:ring-darkMode-100 ring-1 ring-gray-300 dark:hover:border-darkMode-150 focus:z-10 border border-gray-300 dark:border-darkMode-100`}
                      >
                        1W
                      </button>

                      {/* The 1M Button */}
                      <button
                        type="button"
                        onClick={() => changeDate('1M')}
                        className={`relative -ml-px inline-flex items-center px-3 py-1 text-sm font-semibold 
                    ${
                      selectedTimeRange === '1M'
                        ? 'bg-blue-600 text-white'
                        : 'dark:bg-darkMode-200 dark:text-gray-400 hover:bg-gray-50 hover:dark:bg-darkMode-100'
                    }
                    dark:ring-darkMode-100 ring-1 ring-gray-300 dark:hover:border-darkMode-150 focus:z-10 border border-gray-300 dark:border-darkMode-100`}
                      >
                        1M
                      </button>

                      {/* The 1Y Button */}
                      <button
                        type="button"
                        onClick={() => changeDate('1Y')}
                        className={`relative -ml-px inline-flex items-center px-3 py-1 text-sm font-semibold 
                    ${
                      selectedTimeRange === '1Y'
                        ? 'bg-blue-600 text-white'
                        : 'dark:bg-darkMode-200 dark:text-gray-400 hover:bg-gray-50 hover:dark:bg-darkMode-100'
                    }
                    dark:ring-darkMode-100 ring-1 ring-gray-300 dark:hover:border-darkMode-150 focus:z-10 border border-gray-300 dark:border-darkMode-100 rounded-r-md`}
                      >
                        1Y
                      </button>
                    </span>
                  </div>

                  {/* The rest of your component stays the same */}
                  <div className="mt-2" style={{ position: 'relative' }}>
                    {isLoading && (
                      <div
                        className="dark:bg-darkMode-200 dark:bg-opacity-50 text-gray-500"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 100,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          background: 'transparent',
                        }}
                      >
                        <PuffLoader
                          color="hsla(194, 67%, 53%, 1)"
                          loading={true}
                          size={100}
                        />
                      </div>
                    )}
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                      key={selectedTimeRange}
                    />
                  </div>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center shadow dark:bg-darkMode-200  dark:border-darkMode-100 dark:text-white ring-offset-transparent transition rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => props.setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default UpdateAccount;
